import React from 'react';
import { getBoolValue, toAbsoluteUrl } from '../../../_metronic';
import {
  SingleLine,
  MultiLine,
  Date,
  DateTime,
  DropDown,
  RadioButtons,
  Checkboxes,
  FileUpload,
  Currency,
  Percentage,
  Email,
  Decimal,
  URL,
  Image,
  DecisionBox,
  RichText,
  Formula,
  DateFormula
} from './Components/CustomFields/CustomFieldsPreview';

const { REACT_APP_EXPERIMENT_SHOW_DASHBOARD_TAB = false } = process.env;

export const isMozilla = typeof InstallTrigger !== 'undefined';

export const reportsModules = [
  { index: 0, id: 'user', name: 'Users', custom: 'userProfiles', label: 'GENERAL.CAPTION.USER' },
  { index: 1, id: 'employees', name: 'Employees', custom: 'employeeProfiles', label: 'GENERAL.CAPTION.EMPLOYEES' },
  { index: 2, id: 'locations', name: 'Locations Profile', custom: 'locations', label: 'GENERAL.CAPTION.LOCATIONS' },
  { index: 2, id: 'locationsReal', name: 'Locations', custom: 'locationsReal', label: 'GENERAL.CAPTION.LOCATIONSREAL' },
  { index: 3, id: 'categories', name: 'Categories', custom: 'categories', label: 'GENERAL.CAPTION.CATEGORIES' },
  { index: 4, id: 'references', name: 'References', custom: 'categories', label: 'GENERAL.CAPTION.REFERENCES' },
  { index: 5, id: 'assets', name: 'Assets', custom: 'categories', label: 'GENERAL.CAPTION.ASSETS' },
  // { index: 6, id: 'depreciation', name: 'Depreciation', custom: '', label: 'GENERAL.CAPTION.DEPRECIATION' },
  { index: 7, id: 'processLive', name: 'Processes', custom: '', label: 'GENERAL.CAPTION.PROCESSLIVE' },
  { index: 8, id: 'inventorySessions', name: 'Inventories', custom: '', label: 'GENERAL.CAPTION.INVENTORYSESSIONS' },
  { index: 9, id: 'logBook', name: 'Logbook', custom: '', label: 'GENERAL.CAPTION.LOGBOOK' },
  { index: 10, id: 'fieldValuesRepeated', name: 'Field Values Repeated', custom: '', label: 'GENERAL.CAPTION.FIELDVALUESREPEATED' }
];

export const hasAnyReportCollectionToGenerate = (array) => {
  return reportsModules.some(({ id }) => (array || []).includes(id))
};

export const rules = [
  { value: 'ruleOne', label: 'POLICIES.DATE.CYCLE.RULE' },
  { value: 'ruleTwo', label: 'POLICIES.DATE.EQUALS.RULE' },
  { value: 'ruleThree', label: 'POLICIES.TEXT.EQUALS.RULE' }
];

const showDashboard = getBoolValue(REACT_APP_EXPERIMENT_SHOW_DASHBOARD_TAB);

export const modules = [
  ...(showDashboard ? [{ key: 'dashboard', name: 'Dashboard' }] : []),
  { key: 'assets', name: 'MENU.ASSETS' },
  { key: 'processes', name: 'MENU.PROCESSES' },
  { key: 'users', name: 'MENU.USERS' },
  { key: 'employees', name: 'MENU.EMPLOYEES' },
  { key: 'locations', name: 'MENU.LOCATIONS' },
  { key: 'reports', name: 'MENU.REPORTS' },
  { key: 'settings', name: 'MENU.SETTINGS' }
];

export const modulesCatalogues = {
  assets: {
    list: ['assets1', 'assets2'],
    references: 'references',
    categories: 'categories'
  },
  user: {
    list: 'userList',
    references: 'userReferences'
  },
  employees: {
    list: 'employees',
    references: 'employeeReferences'
  },
  locations: {
    list: 'locationsList',
    profiles: 'locations'
  }
};

export const collections = {
  messages: 'messages',
  notifications: 'notifications',
  processApprovals: 'processApprovals',
  assets: {
    module: 'assets',
    name: 'assets'
  },
  references: {
    module: 'assets',
    name: 'references'
  },
  categories: {
    module: 'assets',
    name: 'categories'
  },
  user: {
    module: 'users',
    name: 'user'
  },
  userProfiles: {
    module: 'users',
    name: 'userProfiles'
  },
  employees: {
    module: 'employees',
    name: 'employees'
  },
  employeeProfiles: {
    module: 'employees',
    name: 'employeeProfiles'
  },
  policies: {
    module: 'employees',
    name: 'employeeProfiles'
  },
  processes: {
    module: 'processes',
    name: 'processes'
  },
  processLive: {
    module: 'processes',
    name: 'processes'
  },
  processStages: {
    module: 'processes',
    name: 'processStages'
  },
  locations: {
    module: 'locations',
    name: 'locations'
  },
  reports: {
    module: 'reports',
    name: 'reports'
  }
};

export const CustomFieldsPreview = (props) => {
  const customFieldsPreviewObj = {
    singleLine: <SingleLine {...props} />,
    multiLine: <MultiLine {...props} />,
    date: <Date {...props} />,
    dateTime: <DateTime {...props} />,
    dropDown: <DropDown {...props} />,
    radioButtons: <RadioButtons {...props} />,
    checkboxes: <Checkboxes {...props} />,
    fileUpload: <FileUpload {...props} />,
    currency: <Currency {...props} />,
    percentage: <Percentage {...props} />,
    email: <Email {...props} />,
    decimal: <Decimal {...props} />,
    url: <URL {...props} />,
    imageUpload: <Image {...props} />,
    decisionBox: <DecisionBox {...props} />,
    richText: <RichText {...props} />,
    formula: <Formula {...props} />,
    dateFormula: <DateFormula {...props} />
  };
  return customFieldsPreviewObj[props.type];
};

export const allBaseFields = {
  userList: {
    id: { realId: '_id', validationId: 'id', compLabel: 'ID' },
    userProfile: {
      component: 'dropSelect',
      compLabel: 'Profile Selected',
      validationId: 'selectedUserProfile',
      translation: 'RECORD.CAPTION.PROFILE'
    },
    name: {
      validationId: 'name',
      component: 'textField',
      compLabel: 'Name',
      translation: 'RECORD.CAPTION.NAME'
    },
    lastName: {
      validationId: 'lastName',
      component: 'textField',
      compLabel: 'Last Name',
      translation: 'RECORD.CAPTION.LASTNAME'
    },
    email: {
      validationId: 'email',
      component: 'textField',
      compLabel: 'Email',
      translation: 'RECORD.CAPTION.EMAIL'
    },
    password: {
      validationId: 'password',
      component: 'textField',
      compLabel: 'Password',
      translation: 'USERS.CAPTION.PASSWORD'
    },
    boss: {
      component: 'dropSelect',
      compLabel: 'Boss',
      style: { marginTop: '15px' },
      validationId: 'selectedBoss',
      translation: 'USERS.CAPTION.BOSS'
    },
    groups: {
      component: 'dropSelect',
      compLabel: 'Groups',
      style: { marginTop: '15px' },
      validationId: 'groups',
      translation: 'TABS.TITLES.GROUPS'
    }
  },
  userReferences: {
    id: { realId: '_id', validationId: 'id', compLabel: 'ID' },
    name: {
      validationId: 'name',
      component: 'textField',
      compLabel: 'Name',
      translation: 'RECORD.CAPTION.NAME'
    }
  },
  categories: {
    id: { realId: '_id', validationId: 'id', compLabel: 'ID' },
    name: {
      validationId: 'name',
      component: 'textField',
      compLabel: 'Name',
      translation: 'RECORD.CAPTION.NAME'
    },
    depreciation: {
      validationId: 'depreciation',
      component: 'textField',
      compLabel: 'Depreciation',
      translation: 'CATEGORIES.CAPTION.DEPRECIATION'
    }
  },
  references: {
    id: { realId: '_id', validationId: 'id', compLabel: 'ID' },
    category: {
      validationId: 'selectedProfile',
      component: 'dropSelect',
      compLabel: 'Category',
      translation: 'GENERAL.CAPTION.CATEGORY'
    },
    name: {
      validationId: 'name',
      component: 'textField',
      compLabel: 'Name',
      translation: 'RECORD.CAPTION.NAME'
    },
    brand: {
      validationId: 'brand',
      component: 'textField',
      compLabel: 'Brand',
      translation: 'ASSETS.CAPTION.BRAND'
    },
    model: {
      validationId: 'model',
      component: 'textField',
      compLabel: 'Model',
      translation: 'ASSETS.CAPTION.MODEL'
    },
    price: {
      validationId: 'price',
      component: 'textField',
      compLabel: 'Price',
      translation: 'GENERAL.CAPTION.PRICE'
    },
    depreciation: {
      validationId: 'depreciation',
      component: 'textField',
      compLabel: 'Depreciation',
      translation: 'CATEGORIES.CAPTION.DEPRECIATION'
    }
  },
  employees: {
    id: { realId: '_id', validationId: 'id', compLabel: 'ID' },
    employeeProfile: {
      validationId: 'employeeProfile',
      component: 'dropSelect',
      compLabel: 'Employee Profile',
      translation: 'RECORD.CAPTION.PROFILE'
    },
    name: {
      validationId: 'name',
      component: 'textField',
      compLabel: 'Name',
      translation: 'RECORD.CAPTION.NAME'
    },
    lastName: {
      validationId: 'lastName',
      component: 'textField',
      compLabel: 'Last Name',
      translation: 'RECORD.CAPTION.LASTNAME'
    },
    email: {
      validationId: 'email',
      component: 'textField',
      compLabel: 'Email',
      translation: 'RECORD.CAPTION.EMAIL'
    },
    employee_id: {
      validationId: 'employee_id',
      component: 'textField',
      compLabel: 'Employee Id',
      translation: 'EMPLOYEE.CAPTION.ID'
    },
    responsibilityLayout: {
      validationId: 'layoutSelected',
      component: 'dropSelect',
      compLabel: 'Responsibility Layout',
      translation: 'EMPLOYEE.CAPTION.LAYOUT'
    }
  },
  employeeReferences: {
    id: { realId: '_id', validationId: 'id', compLabel: 'ID' },
    name: {
      validationId: 'name',
      component: 'textField',
      compLabel: 'Name',
      translation: 'RECORD.CAPTION.NAME'
    }
  },
  locations: {
    id: { realId: '_id', validationId: 'id', compLabel: 'ID' },
    selectedLevel: {
      validationId: 'level',
      component: 'textField',
      compLabel: 'Level',
      translation: 'LOCATIONS.CAPTION.SELECTED.LEVEL'
    },
    name: {
      validationId: 'name',
      component: 'textField',
      compLabel: 'Name',
      translation: 'RECORD.CAPTION.NAME'
    }
  },
  locationsList: {
    id: { realId: '_id', validationId: 'id', compLabel: 'ID' },
    name: {
      validationId: 'name',
      component: 'textField',
      compLabel: 'Name',
      translation: 'RECORD.CAPTION.NAME'
    },
    fileExt: { validationId: 'fileExt', compLabel: 'Layout', translation: 'LOCATIONS.CAPTION.LAYOUT' },
    //imageInfo: { validationId: 'imageInfo', compLabel: 'Pin Layout', translation: 'LOCATIONS.CAPTION.PIN.LAYOUT' },
    mapInfo: { validationId: 'mapInfo', compLabel: 'Pin Map', translation: 'LOCATIONS.CAPTION.PIN.MAP' }
  },
  assets1: {
    id: { 
      realId: '_id',
      component: 'textField',
      validationId: 'id',
      compLabel: 'ID',
      translation: 'RECORD.CAPTION.ID'
    },
    name: {
      validationId: 'name',
      component: 'textField',
      compLabel: 'Name',
      translation: 'RECORD.CAPTION.NAME'
    },
    brand: {
      validationId: 'brand',
      component: 'textField',
      compLabel: 'Brand',
      translation: 'ASSETS.CAPTION.BRAND'
    },
    model: {
      validationId: 'model',
      component: 'textField',
      compLabel: 'Model',
      translation: 'ASSETS.CAPTION.MODEL'
    },
    category: {
      validationId: 'category',
      component: 'textField',
      compLabel: 'Category',
      translation: 'GENERAL.CAPTION.CATEGORY'
    },
    status: {
      validationId: 'status',
      component: "dropSelect",
      compLabel: 'Status',
      translation: 'GENERAL.CAPTION.STATUS'
    },
    serial: {
      validationId: 'serial',
      component: 'textField',
      compLabel: 'Serial Number',
      translation: 'GENERAL.CAPTION.SERIALNUMBER'
    },
    parent: {
      validationId: 'parent',
      component: 'textField',
      compLabel: 'Parent',
      translation: 'GENERAL.CAPTION.PARENT'
    },
    responsible: {
      validationId: 'responsible',
      component: 'textField',
      compLabel: 'Responsible',
      translation: 'ASSETS.CAPTION.RESPONSIBLE'
    },
    notes: {
      validationId: 'notes',
      component: 'textField',
      compLabel: 'Notes',
      translation: 'ASSETS.CAPTION.NOTES'
    },
    quantity: {
      validationId: 'quantity',
      component: 'textField',
      compLabel: 'Quantity',
      translation: 'ASSETS.CAPTION.QUANTITY'
    }
  },
  assets2: {
    purchaseDate: {
      validationId: 'purchaseDate',
      component: 'textField',
      compLabel: 'Purchase Date',
      translation: 'ASSETS.CAPTION.PURCHASEDATE'
    },
    purchasePrice: {
      validationId: 'purchasePrice',
      component: 'textField',
      compLabel: 'Purchase Price',
      translation: 'ASSETS.CAPTION.PURCHASEPRICE'
    },
    price: {
      validationId: 'price',
      component: 'textField',
      compLabel: 'Price',
      translation: 'GENERAL.CAPTION.PRICE'
    },
    totalPrice: {
      validationId: 'totalPrice',
      component: 'textField',
      compLabel: 'Total Price',
      translation: 'ASSETS.CAPTION.TOTALPRICE'
    },
    EPC: {
      validationId: 'EPC',
      component: 'textField',
      compLabel: 'EPC',
      translation: 'ASSETS.CAPTION.EPC'
    },
    location: {
      validationId: 'location',
      component: 'textField',
      compLabel: 'Location',
      translation: 'GENERAL.CAPTION.LOCATION'
    },
    locationPath: {
      validationId: 'locationPath',
      component: 'textField',
      compLabel: 'Location Path',
      translation: 'GENERAL.CAPTION.LOCATIONPATH'
    },
    creator: {
      validationId: 'creator',
      component: 'textField',
      compLabel: 'Creator',
      translation: 'RECORD.CAPTION.CREATOR'
    },
    creationDate: {
      validationId: 'creationDate',
      component: 'textField',
      compLabel: 'Creation Date',
      translation: 'RECORD.CAPTION.CREATIONDATE'
    },
    labelingUser: {
      validationId: 'labelingUser',
      component: 'textField',
      compLabel: 'Labeling User',
      translation: 'ASSETS.CAPTION.LABELINGUSER'
    },
    labelingDate: {
      validationId: 'labelingDate',
      component: 'textField',
      compLabel: 'Labeling Date',
      translation: 'ASSETS.CAPTION.LABELINGDATE'
    }
  },
  assetEdition: {
    purchaseDate: {
      validationId: 'purchaseDate',
      component: 'textField',
      compLabel: 'Purchase Date',
      translation: 'ASSETS.CAPTION.PURCHASEDATE'
    },
    purchasePrice: {
      validationId: 'purchasePrice',
      component: 'textField',
      compLabel: 'Purchase Price',
      translation: 'ASSETS.CAPTION.PURCHASEPRICE'
    },
    price: { 
      validationId: 'price',
      component: 'textField',
      compLabel: 'Price',
      translation: 'GENERAL.CAPTION.PRICE'
    },
    serial: { 
      validationId: 'serial',
      component: 'textField',
      compLabel: 'Serial Number',
      translation: 'GENERAL.CAPTION.SERIALNUMBER'
    },
    notes: { 
      validationId: 'notes',
      component: 'textField',
      compLabel: 'Notes',
      translation: 'ASSETS.CAPTION.NOTES'
    },
    quantity: { 
      validationId: 'quantity', 
      component: 'textField', 
      compLabel: 'Quantity',
      translation: 'ASSETS.CAPTION.QUANTITY'
    }
  }
};

export const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/226-united-states.svg'),
    translation: 'TOPBAR.ENGLISH'
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: toAbsoluteUrl('/media/flags/252-mexico.svg'),
    translation: 'TOPBAR.SPANISH'
  }
];

String.prototype.capitalize = function () {
  if (!this) {
    return '';
  }

  return this.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};
