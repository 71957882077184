/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import SwipeableViews from "react-swipeable-views";
import { isEmpty, omit } from "lodash";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";
import {
  Button,
  Dialog,
  DialogTitle,
  InputAdornment,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import TimelineIcon from "@material-ui/icons/Timeline";
import { actions } from "../../../../store/ducks/general.duck";
import {
  postDB,
  getOneDB,
  updateDB,
  findAndModifyDB,
  updateManyDB,
  getDBComplex,
} from "../../../../crud/api";
import {
  getFieldCaption,
  GetTranslatedValue,
  updateAssetInfoToRelated,
  getCurrentDateTime,
} from "../../utils";
import BaseFields from "../../Components/BaseFields/BaseFields";
import ImageUpload from "../../Components/ImageUpload";
import {
  getFileExtension,
  saveImage,
  getImageURL,
  getLocationPath,
  verifyCustomFields,
  updateCustomFields,
  compareCustomFieldsObjects,
} from "../../utils";
import { allBaseFields, CustomFieldsPreview } from "../../constants";
import "./ModalAssetList.scss";
import OtherModalTabs from "../components/OtherModalTabs";
import {
  executeOnFieldPolicy,
  executeOnLoadPolicy,
  executePolicies,
} from "../../Components/Policies/utils";
import ModalYesNo from "../../Components/ModalYesNo";
import CustomRecordModal from "../../Components/CustomRecordModal";
import TableComponent from "../../Components/TableComponent";

// Example 4 - Tabs
function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

// Example 1 - TextField
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}));

const ModalAssetList = ({
  allFields,
  assets,
  fields,
  fieldsToValidate,
  readOnly,
  showModal,
  setShowModal,
  referencesSelectedId,
  reloadTable,
  id,
  policies,
  parentLocations,
  profilePermissions,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    showCustomAlert,
    showErrorAlert,
    showFillFieldsAlert,
    showSavedAlert,
    showUpdatedAlert,
  } = actions;

  // Other Tab
  const [assetLocation, setAssetLocation] = useState("");
  const [locationReal, setLocationReal] = useState("");
  const [layoutMarker, setLayoutMarker] = useState();
  const [mapMarker, setMapMarker] = useState();
  const [assetsBeforeSaving, setAssetsBeforeSaving] = useState([]);
  const [assetsToDelete, setAssetsToDelete] = useState([]);
  const [showAssignedConfirmation, setShowAssignedConfirmation] = useState(
    false
  );

  const [showEPCWarning, setShowEPCWarning] = useState(false);
  const [assetsAssigned, setAssetsAssigned] = useState([]);
  const [confirmationText, setConfirmationText] = useState("");
  const [isAssetDecommissioned, setIsAssetDecommissioned] = useState(false);

  // Example 4 - Tabs
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  function handleChange4(event, newValue) {
    setValue4(newValue);
  }
  const handleChangeIndex4 = (index) => setValue4(index);
  // Example 5 - Tabs
  const [openHistory, setOpenHistory] = useState(false);
  const [customFieldsPathResponse, setCustomFieldsPathResponse] = useState();
  const [referenceImage, setReferenceImage] = useState("");

  const executeFieldCaption = (catalogue, { field, key }) => {
    return getFieldCaption(allFields, { catalogue, field, key });
  };

  const getTotalPrice = (children) => {
    const price = children.reduce((acc, cur) => {
      return String(parseInt(acc) + parseInt(cur.price || "0"));
    }, "0");

    return price;
  };

  const assetsHeadRows = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption("assets", {
        field: "name",
        key: allBaseFields.assets1.name.translation,
      }),
    },
    {
      id: "brand",
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption("assets", {
        field: "brand",
        key: allBaseFields.assets1.brand.translation,
      }),
    },
    {
      id: "model",
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption("assets", {
        field: "model",
        key: allBaseFields.assets1.model.translation,
      }),
    },
    {
      id: "serial",
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption("assets", {
        field: "serialNumber",
        key: allBaseFields.assets1.serial.translation,
      }),
    },
    {
      id: "EPC",
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption("assets", {
        field: "EPC",
        key: allBaseFields.assets2.EPC.translation,
      }),
    },
    {
      id: "locationPath",
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue("GENERAL.CAPTION.LOCATIONPATH"),
    },
  ];

  const createAssetRow = (
    id,
    name,
    brand,
    model,
    parent,
    EPC,
    serial,
    creationDate,
    price
  ) => {
    return { id, name, brand, model, parent, EPC, serial, creationDate, price };
  };

  const createAssetEPC = () => {
    const { dateFormatted, timeFormatted } = getCurrentDateTime();
    let actualEPC = values.EPC;
    let newVirtualEPC = "";

    findAndModifyDB({
      collection: "helpers",
      query: [{ name: "assetsVirtualEPC" }],
      update: { $inc: { value: 1 } },
    })
      .then((response) => response.json())
      .then( (data) => {
        if (!data.response || isEmpty(data.response)) {
          const body = {
            name: "assetsVirtualEPC",
            value: 1,
          };
          postDB("helpers", body);
        }

        let biggestEPC = data?.response?.value || 0;
        const whiteFolio = [0, 0, 0, 0, 0, 0, 0, 0];

        const prefix = whiteFolio.slice(0,8 - String(biggestEPC + 1).length);
        const newEPC = prefix.join("").concat(String(biggestEPC + 1));

        newVirtualEPC = `VIRTUAL_EPC_${newEPC}`;
        setValues((prev) => ({ ...prev, EPC: newVirtualEPC }));

        //HANDLE SAVE HISTORY ON CHANGE EPC
        values.history = [
          ...values.history || "",
          {
            processId: "EPC",
            processName: `The previous EPC ${actualEPC} was updated by the new virtual EPC ${newVirtualEPC} `,
            processType: "updated",
            label: `EPC updated  on  location: ${values.locationPath}`,
            date: `${dateFormatted} ${timeFormatted}`,
          },
        ];
        setShowEPCWarning(false);
        // handleSave();

      })
      .catch((error) => console.log(error));
  };

  const handleChange = (name) => (event) => {
    if(name === 'EPC'){
      setShowEPCWarning(true);
      return;
    }
    
    setSelectedStatus(event?.value);
    const value = name === "status" ? event : event.target.value;

    if (name === "status") {
      setValues((prev) => ({
        ...prev,
        [name]: value.value,
        status: value.value,
      }));
    } else {
      setValues((prev) => ({ ...prev, [name]: value }));
    }
    // setValues({ ...values, [name]: event.target.value });
  };

  const handleChildrenOnSaving = (parentId) => {
    assetsToDelete.map((asset) => {
      updateDB("assets/", { parent: "" }, asset.id)
        .then((response) => {})
        .catch((error) => {
          dispatch(showErrorAlert());
        });
    });
    assetsBeforeSaving.map((asset) => {
      updateDB("assets/", { parent: parentId }, asset.id)
        .then((response) => {})
        .catch((error) => {
          dispatch(showErrorAlert());
        });
    });
  };

  const handleOnAssetFinderSubmit = (filteredRows) => {
    let assetsAlreadyAssigned = [];
    filteredRows.rows.map((rowTR) => {
      if (rowTR.id === id[0]) {
        const message = intl.formatMessage({
          id: "WARNING.ASSETS.ASSIGNED.ITSELF",
          defaultMessage: "An asset cannot be its own child",
        });
        dispatch(
          showCustomAlert({
            message,
            open: true,
            type: "warning",
          })
        );
      } else {
        if (!assetsBeforeSaving.find((row) => row.id === rowTR.id)) {
          getOneDB("assets/", rowTR.id)
            .then((response) => response.json())
            .then((data) => {
              const { response } = data;
              const {
                _id,
                name,
                brand,
                model,
                parent,
                EPC,
                serial,
                creationDate,
                price,
                locationPath,
                status,
              } = response;

              if (!parent) {
                setAssetsBeforeSaving((prev) => [
                  ...prev,
                  createAssetRow(
                    _id,
                    name,
                    brand,
                    model,
                    null,
                    EPC,
                    serial,
                    creationDate,
                    price
                  ),
                ]);
              } else {
                const asset = {
                  ...createAssetRow(
                    _id,
                    name,
                    brand,
                    model,
                    parent,
                    EPC,
                    serial,
                    creationDate
                  ),
                  locationPath,
                };
                assetsAlreadyAssigned.push(asset);
              }
            })
            .catch((error) => {})
            .finally(() => {
              if (assetsAlreadyAssigned.length) {
                setAssetsAssigned(assetsAlreadyAssigned);
                setShowAssignedConfirmation(true);
                const table = (
                  <TableComponent
                    headRows={assetsHeadRows}
                    noSelect
                    permissions={[]}
                    rows={assetsAlreadyAssigned}
                    title={""}
                  />
                );
                setConfirmationText(<>{table}</>);
              }
            });
        } else {
          const message = intl.formatMessage({
            id: "WARNING.ASSETS.ASSIGNED",
            defaultMessage: "Text not rendered",
          });
          dispatch(
            showCustomAlert({
              message,
              open: true,
              type: "warning",
            })
          );
        }
      }
    });
  };

  const handleOnDeleteAssetAssigned = (id) => {
    const restRows = assetsBeforeSaving.filter((row) => {
      if (!id.includes(row.id)) {
        return row;
      }
      if (!row.parentId) {
        setAssetsToDelete((prev) => [...prev, row]);
      }
    });
    setAssetsBeforeSaving(restRows);
  };

  const [formValidation, setFormValidation] = useState({
    enabled: false,
    isValidForm: {},
  });

  // Example 1 - TextField
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    brand: "",
    model: "",
    category: "",
    status: "",
    serial: "",
    assignedTo: "",
    notes: "",
    quantity: 0,
    purchase_date: "",
    purchase_price: "0",
    price: "0",
    total_price: "0",
    EPC: "",
    location: "",
    locationPath: "",
    creator: "",
    creationDate: "",
    labeling_user: "",
    labeling_date: "",
    parent: "",
  });
  const [customFieldsTab, setCustomFieldsTab] = useState({});
  const [tabs, setTabs] = useState([]);

  const [allStatus, setAllStatus] = useState([]);
  const [actualStatus, setActualStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const optionsStatus = [
    { value: "active", label: "active" },
    { value: "decommissioned", label: "decommissioned" },
  ];

  const statusPermission = profilePermissions?.assets?.list.includes("status");
  const epcPermission = profilePermissions?.assets?.list.includes("epc")

  const baseFieldsLocalProps = {
    id: {
      componentProps: {
        onChange: () => {},
        value: values.id || "",
        inputProps: {
          readOnly: true,
        },
      },
    },
    name: {
      componentProps: {
        onChange: handleChange("name"),
        value: values.name || "",
        inputProps: {
          readOnly: true,
        },
      },
    },
    brand: {
      componentProps: {
        onChange: handleChange("brand"),
        value: values.brand || "",
        inputProps: {
          readOnly: true,
        },
      },
    },
    model: {
      componentProps: {
        onChange: handleChange("model"),
        value: values.model || "",
        inputProps: {
          readOnly: true,
        },
      },
    },
    category: {
      componentProps: {
        onChange: handleChange("category"),
        value: values.category?.label || "",
        inputProps: {
          readOnly: true,
        },
      },
    },
    status: {
      componentProps: {
        onChange: handleChange("status"),
        options: allStatus,
        value: { value: values.status, label: values.status },
        isDisabled:
          values.status === "maintenance" ||
          values.status === "inProcess" ||
          !statusPermission
            ? true
            : false,
      },
    },
    serial: {
      componentProps: {
        onChange: handleChange("serial"),
        value: values.serial || "",
      },
    },
    parent: {
      componentProps: {
        onChange: handleChange("parent"),
        value: values.parent || "No Parent Assigned",
        inputProps: {
          readOnly: true,
          shrink: true,
        },
      },
    },
    responsible: {
      componentProps: {
        onChange: handleChange("responsible"),
        value: values.assignedTo || "",
        inputProps: {
          readOnly: true,
          shrink: true,
        },
      },
    },
    notes: {
      componentProps: {
        onChange: handleChange("notes"),
        value: values.notes || "",
        multiline: true,
        rows: 4,
        inputProps: {
          readOnly,
        },
      },
    },
    quantity: {
      componentProps: {
        onChange: handleChange("quantity"),
        value: Number(values.quantity),
        type: "number",
        inputProps: {
          readOnly: true,
        },
      },
    },
    purchaseDate: {
      ownValidFn: () => !isEmpty(values.purchase_date),
      componentProps: {
        onChange: handleChange("purchase_date"),
        value: values.purchase_date || "",
        type: "date",
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    purchasePrice: {
      ownValidFn: () =>
        !!values.purchase_price || values.purchase_price === "0",
      componentProps: {
        onChange: handleChange("purchase_price"),
        value: values.purchase_price,
        type: "number",
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          readOnly,
        },
      },
    },
    price: {
      ownValidFn: () => !!values.price || values.price === 0,
      componentProps: {
        onChange: handleChange("price"),
        value: values.price,
        type: "number",
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          readOnly,
        },
      },
    },
    totalPrice: {
      ownValidFn: () => !!values.total_price || values.total_price === "0",
      componentProps: {
        onChange: handleChange("total_price"),
        value: values.total_price,
        type: "number",
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          readOnly: true,
        },
      },
    },
    EPC: {
      componentProps: {
        onChange: handleChange("EPC"),
        value: values.EPC || "",
        inputProps: {
          readOnly: values.EPC.includes("VIRTUAL_EPC") || !epcPermission ? true : false
        },
        type: "search"
      },
    },
    location: {
      componentProps: {
        onChange: handleChange("location"),
        value: values.location || "",
        hidden: true,
        inputProps: {
          readOnly: true,
        },
      },
    },
    locationPath: {
      componentProps: {
        onChange: handleChange("locationPath"),
        values: values.locationPath || "",
        multine: true,
        inputProps: {
          readOnly: true,
        },
      },
    },
    creator: {
      componentProps: {
        onChange: handleChange("creator"),
        value: values.creator || "",
        inputProps: {
          readOnly: true,
        },
      },
    },
    creationDate: {
      componentProps: {
        onChange: handleChange("creationDate"),
        value: values.creationDate || "",
        inputProps: {
          readOnly: true,
        },
      },
    },
    labelingUser: {
      componentProps: {
        onChange: handleChange("labeling_user"),
        value: values.creation_date || "",
        inputProps: {
          readOnly: true,
        },
      },
    },
    labelingDate: {
      componentProps: {
        onChange: handleChange("labeling_date"),
        value: values.creation_date || "",
        inputProps: {
          readOnly: true,
        },
      },
    },
  };

  console.log(values.purchase_price, values.price, values.total_price);

  const handleLoadCustomFields = (profile) => {
    if (!profile || !profile.id) return;
    getOneDB("categories/", profile.id)
      .then((response) => response.json())
      .then((data) => {
        const { customFieldsTab, depreciation } = data.response;
        const tabs = Object.keys(customFieldsTab).map((key) => ({
          key,
          info: customFieldsTab[key].info,
          content: [customFieldsTab[key].left, customFieldsTab[key].right],
        }));
        tabs.sort((a, b) => a.key.split("-").pop() - b.key.split("-").pop());

        setCustomFieldsTab(customFieldsTab);
        setValues((prev) => ({ ...prev, depreciation }));
        setTabs(tabs);
      })
      .catch((error) => {
        dispatch(showErrorAlert());
      });
  };

  const handleSave = () => {
    setFormValidation({ ...formValidation, enabled: true });
    if (!isEmpty(formValidation.isValidForm)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    if (!verifyCustomFields(customFieldsTab)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    const fileExt = getFileExtension(image);

    let reassignedAssets = [];
    assetsBeforeSaving.forEach(({ parent, id: assetId }) => {
      const index = reassignedAssets.findIndex(
        (element) => element.parentId === parent
      );
      if (index !== -1) {
        reassignedAssets[index].assets = [
          ...reassignedAssets[index].assets,
          assetId,
        ];
      } else if (parent) {
        reassignedAssets.push({ parentId: parent, assets: [assetId] });
      }
    });

    if (reassignedAssets.length) {
      reassignedAssets.forEach(({ assets, parentId }) => {
        if (parentId !== id[0]) {
          getOneDB("assets/", parentId)
            .then((response) => response.json())
            .then((data) => {
              const {
                response: { children },
              } = data;
              const newChildren = children.filter(
                ({ id: assetId }) => !assets.includes(assetId)
              );
              updateDB(
                "assets/",
                { children: newChildren },
                parentId
              ).catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
      });
    }

    const parseAssetsAssigned = assetsBeforeSaving.map(
      ({ id, name, brand, model, EPC, serial, price }) => ({
        id,
        name,
        brand,
        model,
        EPC,
        serial,
        price,
      })
    );

    //HANDLE SAVE HISTORY OF CHANGE STATUS
    if (selectedStatus !== "" && selectedStatus !== actualStatus) {
      const { dateFormatted, timeFormatted } = getCurrentDateTime();

      values.history = [
        ...values.history || "",
        {
          processId: "Status",
          processName: `Status updated in assets list to ${selectedStatus} `,
          processType: "updated",
          label: `${values.name} with EPC:${values.EPC} updated  in location: ${values.locationPath} to status: ${selectedStatus}`,
          date: `${dateFormatted} ${timeFormatted}`,
        },
      ];
    }

    const { creationDate, ...otherValues } = values;
    const body = {
      ...omit(otherValues, ["parent"]),
      customFieldsTab,
      fileExt,
      layoutCoords: layoutMarker ? layoutMarker : null,
      mapCoords: mapMarker ? mapMarker : null,
      children: parseAssetsAssigned,
    };

    if (!id) {
      body.referenceId = referencesSelectedId;
      postDB("assets", body)
        .then((data) => data.json())
        .then((response) => {
          dispatch(showSavedAlert());
          const { _id } = response.response[0];
          handleChildrenOnSaving(_id);
          saveAndReload("assets", _id);
          executePolicies(
            "OnAdd",
            "assets",
            "list",
            policies,
            response.response[0]
          );
          executeOnFieldPolicy(
            "OnAdd",
            "assets",
            "list",
            policies,
            response.response[0]
          );
        })
        .catch((error) => {
          dispatch(showErrorAlert());
        });
    } else {
      updateDB("assets/", body, id[0])
        .then((response) => response.json())
        .then((data) => {
          dispatch(showUpdatedAlert());
          handleChildrenOnSaving(id[0]);
          saveAndReload("assets", id[0]);

          const {
            response: {
              value,
              value: { assigned, _id, creationDate },
            },
          } = data;
          const asset = { _id: id[0], ...body };

          if (
            value.name !== body.name ||
            value.brand !== body.brand ||
            value.model !== body.model ||
            value.EPC !== body.EPC ||
            value.serial !== body.serial
          ) {
            updateAssetInfoToRelated({
              collection: "employees",
              searchField: "assetsAssigned",
              asset: {
                id: _id,
                name: body.name,
                brand: body.brand,
                model: body.model,
                EPC: body.EPC,
                serial: body.serial,
                creationDate,
              },
            });
            updateAssetInfoToRelated({
              collection: "assets",
              searchField: "children",
              asset: {
                id: _id,
                name: body.name,
                brand: body.brand,
                model: body.model,
                EPC: body.EPC,
                serial: body.serial,
                creationDate,
              },
            });
          }

          executePolicies("OnEdit", "assets", "list", policies, asset);
          executeOnFieldPolicy(
            "OnEdit",
            "assets",
            "list",
            policies,
            asset,
            value
          );

          if (assigned) {
            if (assigned.length) {
              getOneDB("employees", assigned)
                .then((response) => response.json())
                .then((data) => {
                  const {
                    response: { assetsAssigned },
                  } = data;
                  let newAssetsAssigned = [];
                  assetsAssigned.forEach(
                    ({
                      id: assetId,
                      name,
                      brand,
                      model,
                      assigned,
                      EPC,
                      serial,
                      price,
                    }) => {
                      if (assetId === id[0]) {
                        newAssetsAssigned.push({
                          id: assetId,
                          name: body.name,
                          brand,
                          model,
                          assigned,
                          EPC,
                          serial: body.serial,
                          price,
                        });
                      } else {
                        newAssetsAssigned.push({
                          assetId,
                          name,
                          brand,
                          model,
                          assigned,
                          EPC,
                          serial,
                          price,
                        });
                      }
                    }
                  );
                  updateDB(
                    "employees/",
                    { assetsAssigned: newAssetsAssigned },
                    assigned
                  ).catch((error) => console.log(error));
                })
                .catch((error) => console.log(error));
            }
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(showErrorAlert());
        });
    }
    handleCloseModal();
  };

  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  const saveAndReload = (folderName, id) => {
    if (!id || (id[0] && initialImage !== image)) {
      saveImage(image, folderName, id);
    }
    reloadTable();
  };

  const handleCloseModal = () => {
    setCustomFieldsTab({});
    setValues({
      name: "",
      brand: "",
      model: "",
      category: "",
      status: "",
      serial: "",
      responsible: "",
      notes: "",
      quantity: 0,
      purchase_date: "",
      purchase_price: "0",
      price: "0",
      total_price: "0",
      EPC: "",
      location: "",
      locationPath: "",
      creator: "",
      creation_date: "",
      labeling_user: "",
      labeling_date: "",
      assignedTo: "",
    });
    setShowModal(false);
    setValue4(0);
    setTabs([]);
    setFormValidation({
      enabled: false,
      isValidForm: false,
    });
    setMapMarker([]);
    setLayoutMarker([]);
    setLocationReal([]);
    setAssetLocation([]);
    setAssetsBeforeSaving([]);
    setAssetsToDelete([]);
    setAssetsAssigned([]);
    setReferenceImage("");
    setShowAssignedConfirmation(false);
    setConfirmationText("");
    setAllStatus([]);
    setActualStatus("");
    setSelectedStatus("");
  };

  useEffect(() => {
    if (assetLocation.length) {
      getOneDB("locationsReal/", assetLocation)
        .then((response) => response.json())
        .then((data) => setLocationReal(data.response))
        .catch((error) => console.log(`Error: ${error}`));
    }
  }, [assetLocation, id]);

  useEffect(() => {
    if (!showModal) {
      return;
    }

    if (referencesSelectedId) {
      getOneDB("references/", referencesSelectedId)
        .then((response) => response.json())
        .then((data) => {
          const {
            name,
            brand,
            model,
            customFieldsTab,
            fileExt,
            selectedProfile,
          } = data.response;
          setValues({
            ...values,
            name,
            brand,
            category: selectedProfile,
            model,
          });
          const tabs = Object.keys(customFieldsTab).map((key) => ({
            key,
            info: customFieldsTab[key].info,
            content: [customFieldsTab[key].left, customFieldsTab[key].right],
          }));
          tabs.sort((a, b) => a.key.split("-").pop() - b.key.split("-").pop());
          setCustomFieldsTab(customFieldsTab);
          setTabs(tabs);
          setReferenceImage(
            getImageURL(referencesSelectedId, "references", fileExt)
          );
        })
        .catch((error) => {
          dispatch(showErrorAlert());
        });
    }

    if (!id || !Array.isArray(id)) {
      return;
    }

    getOneDB("assets/", id[0])
      .then((response) => response.json())
      .then(async (data) => {
        const {
          _id: id,
          name,
          brand,
          model,
          category,
          referenceId,
          status,
          serial,
          responsible,
          notes,
          quantity,
          purchase_date,
          purchase_price,
          price,
          total_price,
          EPC,
          location,
          creationUserFullName,
          creationDate,
          labeling_user,
          labeling_date,
          customFieldsTab,
          fileExt,
          assigned,
          layoutCoords,
          mapCoords,
          children,
          history,
          parent,
          isPhotoShared,
          assignedTo,
        } = data.response;
        const locationPath = await getLocationPath(location);
        const assignedParent = assets.find(({ id }) => id === parent);
        const assignedParentText = assignedParent
          ? `${assignedParent.name || "No Name"}, ${assignedParent.brand ||
              "No Brand"}, ${assignedParent.model ||
              "No Model"}, ${assignedParent.serial || "No Serial Number"}, ${
              assignedParent.EPC ? `(${assignedParent.EPC})` : "No EPC"
            }`
          : null;
        setAssetLocation(location);
        setLayoutMarker(layoutCoords); //* null if not specified
        setMapMarker(mapCoords); //* null if not specified
        setAssetsBeforeSaving(children ? children : []); //* null if not specified
        setIsAssetDecommissioned(status === "decommissioned");
        setAllStatus(optionsStatus);

        getOneDB("references/", referenceId)
          .then((referenceResponse) => referenceResponse.json())
          .then(async (referenceData) => {
            const {
              selectedProfile: { value, label },
              fileExt,
              customFieldsTab: referenceCustomFields,
            } = referenceData.response;
            const onLoadResponse = await executeOnLoadPolicy(
              value,
              "assets",
              "list",
              policies,
              data.response
            );
            setCustomFieldsPathResponse(onLoadResponse);
            setValues((prev) => ({ ...prev, category: { value, label } }));
            setReferenceImage(getImageURL(referenceId, "references", fileExt));

            // Update Reference with its category

            getOneDB("categories/", value)
              .then((response) => response.json())
              .then((data) => {
                if (!data.response) {
                  const tabs = Object.entries(customFieldsTab).map(
                    ([key, value]) => ({
                      key,
                      info: value.info,
                      content: [value.left, value.right],
                    })
                  );
                  tabs.sort(
                    (a, b) => a.key.split("-").pop() - b.key.split("-").pop()
                  );
                  setTabs(tabs);
                  setCustomFieldsTab(customFieldsTab);
                  return;
                }

                const { customFieldsTab: parentCustomFields } = data.response;
                if (parentCustomFields) {
                  updateCustomFields(
                    JSON.parse(JSON.stringify(referenceCustomFields)),
                    parentCustomFields,
                    (customFields) => {
                      const referenceGotUpdated = !compareCustomFieldsObjects(
                        referenceCustomFields,
                        customFields
                      );
                      if (referenceGotUpdated) {
                        updateDB(
                          "references/",
                          { customFieldsTab: customFields },
                          referenceId
                        )
                          .then((response) => {})
                          .catch((error) => {
                            dispatch(showErrorAlert());
                          });
                      }

                      updateCustomFields(
                        JSON.parse(JSON.stringify(customFieldsTab)),
                        referenceGotUpdated
                          ? customFields
                          : referenceCustomFields,
                        (customFields) => {
                          const tabs = Object.entries(customFields).map(
                            ([key, value]) => ({
                              key,
                              info: value.info,
                              content: [value.left, value.right],
                            })
                          );
                          tabs.sort(
                            (a, b) =>
                              a.key.split("-").pop() - b.key.split("-").pop()
                          );
                          setTabs(tabs);
                          setCustomFieldsTab(customFields);
                        }
                      );
                    }
                  );
                }
              })
              .catch((error) => console.log("error", error));
          })
          .catch((error) =>
            showCustomAlert({
              type: "error",
              message: error,
              open: true,
            })
          );

        setValues({
          ...values,
          id,
          name,
          brand,
          model,
          category,
          status,
          serial,
          responsible,
          notes,
          quantity,
          parent: assignedParentText,
          purchase_date,
          purchase_price,
          price,
          total_price: !isEmpty(children) ? getTotalPrice(children) : price,
          EPC,
          location,
          locationPath,
          creator: creationUserFullName,
          creationDate,
          labeling_user,
          labeling_date,
          history,
          imageURL: getImageURL(id, "assets", fileExt),
          assignedTo,
          isPhotoShared,
        });

        setActualStatus(status);
      })
      .catch((error) => {
        dispatch(showErrorAlert());
      });
  }, [showModal]);

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      total_price: !isEmpty(assetsBeforeSaving)
        ? getTotalPrice(assetsBeforeSaving)
        : values.price,
    }));
  }, [assetsBeforeSaving]);

  // Function to update customFields
  const handleUpdateCustomFields = (tab, id, colIndex, CFValues) => {
    const colValue = ["left", "right"];
    const customFieldsTabTmp = { ...customFieldsTab };

    if (!customFieldsTabTmp[tab]) {
      return;
    }
    const field =
      customFieldsTabTmp[tab][colValue[colIndex]].find((cf) => cf.id === id) ||
      {};
    field.values = CFValues;
  };

  return (
    <>
      <ModalYesNo
        message={confirmationText}
        onCancel={() => {
          setShowAssignedConfirmation(false);
          setAssetsAssigned([]);
        }}
        onOK={() => {
          const parseReassignedRows = assetsAssigned.map(
            ({
              id,
              name,
              brand,
              model,
              parent,
              EPC,
              serial,
              creationDate,
              price,
            }) => ({
              ...createAssetRow(
                id,
                name,
                brand,
                model,
                parent,
                EPC,
                serial,
                creationDate,
                price
              ),
            })
          );
          setAssetsBeforeSaving((prev) => [...prev, ...parseReassignedRows]);
          setShowAssignedConfirmation(false);
          setAssetsAssigned([]);
        }}
        showModal={showAssignedConfirmation}
        title={GetTranslatedValue(
          "ASSETS.MODAL.TAB.OTHER.CHILDREN.CAPTION.TITLE"
        )}
      />

       <ModalYesNo
        message={GetTranslatedValue("ASSETS.MODAL.TAB.ASSET.EPC.TEXT")}
        onCancel={() => {
          setShowEPCWarning(false)
        }}
        onOK={() => {createAssetEPC()}}
        showModal={showEPCWarning}
        title={GetTranslatedValue(
          "ASSETS.MODAL.TAB.ASSET.EPC.TITLE"
        )}
      />

      <Dialog
        onClose={() => {
          setOpenHistory(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openHistory}
      >
        <DialogTitle id="simple-dialog-title">History</DialogTitle>
        {!values ||
          (!values.history && (
            <div style={{ padding: "10px", margin: "10px" }}>
              <Typography>This Asset has no History</Typography>
            </div>
          ))}
        {
          <Timeline>
            {values?.history?.map(
              ({ processName, processType, date, label, processId }) => (
                <TimelineItem>
                  <TimelineOppositeContent>
                    <Typography>{processName}</Typography>
                    <Typography color="textSecondary">{`Folio: ${processId}`}</Typography>
                    <Typography color="textSecondary">{processType}</Typography>
                    <Typography color="textSecondary">{date}</Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography>{label}</Typography>
                  </TimelineContent>
                </TimelineItem>
              )
            )}
          </Timeline>
        }
      </Dialog>
      <CustomRecordModal
        id={id}
        handleCloseModal={handleCloseModal}
        handleSave={handleSave}
        key="Modal-Assets"
        readOnly={readOnly}
        showModal={showModal}
        tabs={
          <Tabs
            value={value4}
            onChange={handleChange4}
            indicatorColor="primary"
            textColor="primary"
            variant={tabs.length > 4 ? "scrollable" : "fullWidth"}
          >
            <Tab
              label={GetTranslatedValue("ASSETS.MODAL.TAB.ASSET", "Asset")}
            />
            <Tab label={GetTranslatedValue("ASSETS.MODAL.TAB.MORE", "More")} />
            {tabs.map((tab, index) => (
              <Tab key={`tab-reference-${index}`} label={tab.info.name} />
            ))}
          </Tabs>
        }
        title="ASSETS.MODAL.CAPTION"
      >
        <SwipeableViews
          axis={theme4.direction === "rtl" ? "x-reverse" : "x"}
          index={value4}
          onChangeIndex={handleChangeIndex4}
          style={{ overflowY: "hidden" }}
        >
          <TabContainer4 dir={theme4.direction}>
            <div className="profile-tab-wrapper">
              <div className="profile-tab-wrapper__content-left">
                <ImageUpload
                  disabled={readOnly}
                  id="asset-image"
                  image={values.imageURL}
                  setImage={setImage}
                  setInitialImage={setInitialImage}
                >
                  {GetTranslatedValue(
                    "ASSETS.MODAL.ASSET.PHOTO",
                    "Asset Photo"
                  )}
                </ImageUpload>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<TimelineIcon />}
                  style={{
                    marginTop: "20px",
                    marginBottom: "40px",
                    width: "60%",
                    alignSelf: "center",
                  }}
                  onClick={() => setOpenHistory(true)}
                >
                  {GetTranslatedValue("ASSETS.MODAL.HISTORY", "Asset List")}
                </Button>
                <ImageUpload
                  disabled
                  id="asset-reference-photo"
                  image={referenceImage}
                  showDeleteButton={false}
                  showButton={false}
                >
                  {GetTranslatedValue(
                    "ASSETS.MODAL.REFERENCE.PHOTO",
                    "Reference Photo"
                  )}
                </ImageUpload>
              </div>
              <div className="profile-tab-wrapper__content-left">
                <BaseFields
                  catalogue={"assets1"}
                  collection={"assets"}
                  fields={fields}
                  fieldsToValidate={fieldsToValidate}
                  formState={[formValidation, setFormValidation]}
                  localProps={baseFieldsLocalProps}
                  values={values}
                />
              </div>
              <div className="profile-tab-wrapper__content-left">
                <BaseFields
                  catalogue={"assets2"}
                  collection={"assets"}
                  fields={fields}
                  fieldsToValidate={fieldsToValidate}
                  formState={[formValidation, setFormValidation]}
                  localProps={baseFieldsLocalProps}
                  values={values}
                />
              </div>
            </div>
          </TabContainer4>
          <TabContainer4 dir={theme4.direction}>
            <OtherModalTabs
              isAssetDecommissioned={isAssetDecommissioned}
              key={locationReal ? locationReal : "other-modal-tabs"}
              locationReal={locationReal}
              layoutMarker={layoutMarker}
              setLayoutMarker={setLayoutMarker}
              mapMarker={mapMarker}
              setMapMarker={setMapMarker}
              assetRows={assetsBeforeSaving}
              assetOpened={values}
              onAssetFinderSubmit={handleOnAssetFinderSubmit}
              onDeleteAssetAssigned={handleOnDeleteAssetAssigned}
              parentLocations={parentLocations}
            />
          </TabContainer4>
          {tabs.map((tab) => (
            <TabContainer4 dir={theme4.direction}>
              <div className="modal-asset-reference">
                {Array(tab.content[1].length === 0 ? 1 : 2)
                  .fill(0)
                  .map((col, colIndex) => (
                    <div className="modal-asset-reference__list-field">
                      {tab.content[colIndex].map((customField) => (
                        <CustomFieldsPreview
                          columnIndex={colIndex}
                          customFieldsPathResponse={customFieldsPathResponse}
                          data={tab.content[colIndex]}
                          from="form"
                          id={customField.id}
                          onClick={() => alert(customField.content)}
                          onDelete={() => {}}
                          onSelect={() => {}}
                          onUpdateCustomField={handleUpdateCustomFields}
                          tab={tab}
                          type={customField.content}
                          values={customField.values}
                          readOnly={readOnly}
                          isPhotoShared={values.isPhotoShared?.includes(
                            customField.id
                          )}
                          setIsPhotoShared={(idToDelete) => {
                            const newIsPhotoShared = values.isPhotoShared.filter(
                              (id) => id !== idToDelete
                            );
                            setValues((prev) => ({
                              ...prev,
                              isPhotoShared: newIsPhotoShared,
                            }));
                          }}
                          label={customField.label}
                        />
                      ))}
                    </div>
                  ))}
              </div>
            </TabContainer4>
          ))}
        </SwipeableViews>
      </CustomRecordModal>
    </>
  );
};

export default ModalAssetList;
