import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import HelpIcon from '@material-ui/icons/Help';

class Help extends React.Component {
  render() {
    const { useSVG, iconType, appColors} = this.props;
    return (
      <FormattedMessage id={'TOPBAR.TOOLTIP.HELP'} defaultMessage={'Help'}>
        {
          (msg) => (

            <Link to={'/help'} className='kt-header__topbar-item' drop='down' alignRight="true">
              <span
                className={clsx('kt-header__topbar-icon', {
                  'kt-header__topbar-icon--brand': iconType === 'brand'
                })}
              >
                {useSVG && (
                  <OverlayTrigger
                    placement='bottom'
                    overlay={<Tooltip id='quick-panel-tooltip' style={{ marginTop: '20px' }}>{msg}</Tooltip>}
                  >
                    <span
                      className={clsx('kt-svg-icon', {
                        'kt-svg-icon-brand': iconType === 'brand'
                      })}
                    >
                      <HelpIcon className='kt-svg-icon kt-svg-icon--primary' style={{color: appColors.topNavigationBarIcons}} />
                    </span>
                  </OverlayTrigger>
                )}
              </span>
            </Link>

          )
        }
      </FormattedMessage>
    );
  }
}

const mapStateToProps = ({ design: { appColors } }) => ({
  appColors
});

export default connect(mapStateToProps)(Help);
