import { getBoolValue } from '../../../../../_metronic';
import { reportsModules } from '../../constants';
import { importModulesCatalogues } from '../../Settings/settings-tabs/constants';

const {
  REACT_APP_EXPERIMENT_SHOW_DASHBOARD_TAB = false,
  REACT_APP_SHOW_IMPORT = false
} = process.env;

export const tabsConfig = {
  titles: [
    {
      title: 'List',
      translate: 'TABS.TITLES.LIST'
    },
    {
      title: 'References',
      translate: 'TABS.TITLES.REFERENCES'
    },
    {
      title: 'Categories',
      translate: 'TABS.TITLES.CATEGORIES'
    },
    {
      title: 'Policies',
      translate: 'TABS.TITLES.POLICIES'
    },
    {
      title: 'Stages',
      translate: 'TABS.TITLES.STAGES'
    },
    {
      title: 'Live',
      translate: 'TABS.TITLES.LIVE'
    },
    {
      title: 'Profiles',
      translate: 'TABS.TITLES.PROFILES'
    },
    {
      title: 'Settings',
      translate: 'TABS.TITLES.SETTINGS'
    },
    {
      title: 'General',
      translate: 'TABS.TITLES.GENERAL'
    },
    {
      title: 'Saved',
      translate: 'TABS.TITLES.SAVED'
    },
    {
      title: 'Generated Reports',
      translate: 'TABS.TITLES.DATAEXTRACTION'
    },
    {
      title: 'Design',
      translate: 'TABS.TITLES.DESIGN'
    },
    {
      title: 'Layouts & Presets',
      translate: 'TABS.TITLES.LAYOUTSPRESETS'
    },
    {
      title: 'Fields',
      translate: 'TABS.TITLES.FIELDS'
    },
    {
      title: 'Custom',
      translate: 'TABS.TITLES.CUSTOM'
    },
    {
      title: 'Users',
      translate: 'TABS.TITLES.USERS'
    },
    {
      title: 'Processes',
      translate: 'TABS.TITLES.PROCESSES'
    },
    {
      title: 'Groups',
      translate: 'TABS.TITLES.GROUPS'
    },
    ...(JSON.parse(REACT_APP_SHOW_IMPORT)
      ? [
        {
          title: 'Import',
          translate: 'TABS.TITLES.IMPORT'
        }
      ]
      : [])
  ],
  modules: [
    ...(JSON.parse(REACT_APP_EXPERIMENT_SHOW_DASHBOARD_TAB)
      ? [{ name: 'dashboard', titles: [{ label: 'Dashboards', value: 'dashboards' }] }]
      : []),
    {
      name: 'assets',
      titles: [
        { label: 'List', value: 'list' },
        { label: 'References', value: 'references' },
        { label: 'Categories', value: 'categories' },
        { label: 'Policies', value: 'policies' }
      ]
    },
    {
      name: 'processes',
      titles: [
        { label: 'Live', value: 'live' },
        { label: 'List', value: 'list' },
        { label: 'Stages', value: 'stages' }
      ],
      subtitles: {
        Live: [
          {
            title: 'My Requests',
            subTabs: [],
            translate: 'TABS.SUBTITLES.MYREQUESTS'
          },
          {
            title: 'Pending Approvals',
            subTabs: [],
            translate: 'TABS.SUBTITLES.PENDINGAPPROVALS'
          },
          {
            title: 'Fulfilled Approvals',
            subTabs: [],
            translate: 'TABS.SUBTITLES.FULFILLEDAPPROVALS'
          }
        ]
      },
      others: [
        { label: 'Change Approvals', value: 'changeApprovals', translate: 'GENERAL.CAPTION.CHANGEAPPROVALS' }
      ]
    },
    {
      name: 'users',
      titles: [
        { label: 'List', value: 'list' },
        { label: 'Profiles', value: 'profiles' },
        { label: 'Policies', value: 'policies' }
      ],
      others: [
        { label: 'Password Edition', value: 'passwordChange', translate: 'TABS.TITLES.PASSWORD' }
      ]
    },
    {
      name: 'employees',
      titles: [
        { label: 'List', value: 'list' },
        { label: 'Profiles', value: 'profiles' },
        { label: 'Policies', value: 'policies' }
      ]
    },
    {
      name: 'locations',
      titles: [
        { label: 'List', value: 'list' },
        { label: 'Profiles', value: 'profiles' },
        { label: 'Policies', value: 'policies' }
      ]
    },
    {
      name: 'reports',
      titles: [
        { label: 'General', value: 'general' },
        { label: 'Saved', value: 'saved' },
        { label: 'Generated Reports', value: 'dataExtraction' }
      ]
    },
    {
      name: 'settings',
      titles: [
        { label: 'General', value: 'general' },
        { label: 'Design', value: 'design' },
        { label: 'Layouts & Presets', value: 'layouts & presets' },
        { label: 'Fields', value: 'fields' },
        { label: 'Custom', value: 'custom' },
        { label: 'Users', value: 'users' },
        { label: 'Processes', value: 'processes' },
        { label: 'Groups', value: 'groups' },
        ...(JSON.parse(REACT_APP_SHOW_IMPORT) ? [{ label: 'Import', value: 'import' }] : [])
      ],
      subtitles: {
        Design: [
          {
            title: 'Log In',
            subTabs: [],
            translate: 'TABS.SUBTITLES.LOGIN'
          },
          {
            title: 'Main App',
            subTabs: [
              { title: 'General', subTabs: {}, translate: 'TABS.TITLES.GENERAL' },
              { title: 'Aside', subTabs: {}, translate: 'TABS.SUBTITLES.ASIDE' }
            ],
            translate: 'TABS.SUBTITLES.MAINAPP'
          }
        ],
        'Layouts & Presets': [
          {
            title: 'Employees',
            subTabs: [],
            translate: 'MENU.EMPLOYEES'
          },
          {
            title: 'Stage Layouts',
            subTabs: ['Skins', 'Aside'],
            translate: 'TABS.SUBTITLES.STAGESLAYOUTS'
          }
        ],
        Users: [
          {
            title: 'Assets Specialists',
            subTabs: [],
            translate: 'TABS.SUBTITLES.SPECIALISTS'
          },
          {
            title: 'Witnesses',
            subTabs: ['Skins', 'Aside'],
            translate: 'TABS.SUBTITLES.WITNESSES'
          }
        ]
      }
    }
  ]
};

const items = [
  { key: "add", name: "Add" },
  { key: "edit", name: "Edit" },
  { key: "delete", name: "Delete" },
  { key: "view", name: "View" }
];

const listItems = [
  { key: 'add', name: 'Add' },
  { key: 'edit', name: 'Edit' },
  { key: 'delete', name: 'Delete' },
  { key: 'view', name: 'View' },
  { key: "status", name: "Status"},
  { key: "epc", name: "Remove EPC" }
];


const settingsOnlyEditable = ['general', 'design', 'fields', 'processes'];

const handleAssetsPermissions = () => {
  const { REACT_APP_EXPERIMENT_SHOW_ADD_ASSET_ACTION } = process.env;

  if (getBoolValue(REACT_APP_EXPERIMENT_SHOW_ADD_ASSET_ACTION)) {
    return items;
  } else {
    return listItems.filter(({ key }) => !["add"].includes(key));
  }
};

export const permissionsPerModule = [
  ...tabsConfig.modules.map(({ name, titles, others }) => {
    const obj = {};

    titles.forEach(({ value }) => {
      if (name === 'reports' && value === 'general') {
        obj[value] = reportsModules.map(({ id: key, name }) => ({ key, name }));
      } else if (name === 'reports' && value === 'dataExtraction') {
        obj[value] = items.filter(({ key }) => ['delete', 'view'].includes(key));
      } else if (name === 'settings' && settingsOnlyEditable.includes(value)) {
        obj[value] = items.filter(({ key }) => !['add', 'delete'].includes(key));
      } else if (name === 'settings' && value === 'import') {
        const modules = Object.entries(importModulesCatalogues).reduce((acc, cur) => {
          return acc.concat(cur[1].map(({ id, label }) => ({ key: id, name: label })));
        }, []);

        obj[value] = modules;
      } else {
        obj[value] =
          name === 'assets' && value === 'list'
            ? handleAssetsPermissions()
            : value === 'import'
              ? items.filter(({ key }) => !['delete'].includes(key))
              : items;
      }
    });

    (others || []).forEach((other) => {
      obj['others'] = {
        ...(obj['others'] || {}),
        [other.value]: []
      };
    });

    return { key: name, permissions: obj };
  }),
  { key: 'app', permissions: { Tagging: [], Inventory: [] } }
];
