import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { metronic } from '../../../_metronic';
import { languages } from '../../pages/home/constants';
import { getDB, updateDB } from '../../crud/api';
import { actions } from '../../store/ducks/general.duck';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { getFirstDocCollection, GetTranslatedValue } from '../../pages/home/utils';

const LanguageSelector = ({ lang, iconType, setLanguage }) => {
  const dispatch = useDispatch();
  const { showErrorAlert } = actions;
  const [currentLanguage, setCurrentLanguage] = useState(languages.find((x) => x.lang === lang));

  const languageError = () => {
    dispatch(
      showErrorAlert({
        message: 'There was a problem in changing the language',
        open: true,
        type: 'error'
      })
    );
  };

  const handleLanguageChange = (language) => {
    getFirstDocCollection('settingsGeneral')
      .then((id) => {
        updateDB('settingsGeneral/', { selectedLanguage: language.lang }, id)
          .then(() => {
            setLanguage(language.lang);
            window.location.reload();
          })
          .catch((error) => languageError());
      })
      .catch((error) => languageError());
  };

  useEffect(() => {
    getDB('settingsGeneral')
      .then((response) => response.json())
      .then((data) => {
        if (!isEmpty(data.response)) {
          const { selectedLanguage } = data.response[0];
          const currentLang = languages.find((x) => x.lang === selectedLanguage);
          setCurrentLanguage(currentLang);
          setLanguage(currentLang.lang);
        }
      })
      .catch(() => dispatch(showErrorAlert()));
  }, []);

  useEffect(() => {
    const currentLang = languages.find((x) => x.lang === lang);
    setCurrentLanguage(currentLang);
  }, [lang])

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--langs"
      drop="down"
      alignRight="true"
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
        <span
          className={clsx('kt-header__topbar-icon', {
            'kt-header__topbar-icon--brand': iconType === 'brand'
          })}
        >
          <img src={currentLanguage?.flag} alt={currentLanguage?.name} />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
          {languages.map((language) => (
            <li
              key={language.lang}
              className={clsx('kt-nav__item', {
                'kt-nav__item--active': language.lang === currentLanguage?.lang
              })}
            >
              <span
                onClick={() => handleLanguageChange(language)}
                className={clsx('kt-nav__link', {
                  'kt-nav__link--active': language.lang === currentLanguage?.lang
                })}
              >
                <span className="kt-nav__link-icon">
                  <img src={language.flag} alt={language.name} />
                </span>
                <span className="kt-nav__link-text">
                  {GetTranslatedValue(language.translation, language.name)}
                </span>
              </span>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });

export default connect(mapStateToProps, metronic.i18n.actions)(LanguageSelector);
