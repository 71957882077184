/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { GetTranslatedValue } from '../../pages/home/utils';

const defaultPicSrc = '/media/misc/placeholder-image.jpg';

const UserProfile = ({ appColors, user, showHi, showAvatar, showBadge }) => (
  <Dropdown className='kt-header__topbar-item kt-header__topbar-item--user' drop='down' alignRight="true">
    <Dropdown.Toggle
      as={HeaderDropdownToggle}
      id='dropdown-toggle-user-profile'
    >
      <div className='kt-header__topbar-user'>
        {showHi && (
          <span className='kt-header__topbar-welcome kt-hidden-mobile' style={{ color: appColors['topNavigationBarText'] }}>
            Hi,
          </span>
        )}

        {showHi && (
          <span className='kt-header__topbar-username kt-hidden-mobile' style={{ color: appColors['topNavigationBarText'] }}>
            {user.fullname}
          </span>
        )}

        {showAvatar &&
          <img
            alt={defaultPicSrc}
            key={Date.now()}
            onError={(e) => {
              e.target.onError = null;
              e.target.src = defaultPicSrc;
            }}
            src={user.pic}
          />
        }

        {showBadge && (
          <span className='kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold'>
            {/* TODO: Should get from currentUser */}
            John Doe
          </span>
        )}
      </div>
    </Dropdown.Toggle>
    <Dropdown.Menu className='dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
      {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
      <div
        className='kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x'
        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})` }}
      >
        <div className='kt-user-card__avatar'>
          <img
            alt={defaultPicSrc}
            className='kt-hidden'
            key={Date.now()}
            onError={(e) => {
              e.target.onError = null;
              e.target.src = defaultPicSrc;
            }}
            src={user.pic}
          />
          <span className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'>
            {showAvatar &&
              <img
                alt={defaultPicSrc}
                key={Date.now()}
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = defaultPicSrc;
                }}
                src={user.pic}
              />
            }
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='kt-user-card__name'>{user.fullname}</div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <div className='kt-user-card__name' style={{ fontSize: '10px' }}>{user.email}</div>
            {(user.profilePermissions.users?.list || []).includes('view') && (
              <Link
                className='kt-user-card__name'
                to={`users?id=${user.id}`}
                style={{ fontSize: '10px', textDecoration: 'underline' }}
              >
                {GetTranslatedValue('TABS.CAPTION.GO.TO.PROFILE')}
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className='kt-notification'>
        <div className='kt-notification__sign-out'>
          <Link
            to='/logout'
            className='btn btn-label-brand btn-sm btn-bold'
          >
            {GetTranslatedValue('AUTH.GENERAL.SIGN.OUT.BUTTON')}
          </Link>
        </div>
      </div>
    </Dropdown.Menu>
  </Dropdown>
);

const mapStateToProps = ({ auth: { user }, design: { appColors } }) => ({
  user,
  appColors
});

export default connect(mapStateToProps)(UserProfile);
